<template>
  <main>
    <b-overlay :show="loading" rounded="lg" opacity="1">
      <div v-if="validadorBotao == false" class="card p-2">
        <b-col lg="12" md="12" sm="12">
          <h1>Painel por CNPJ</h1>
          <p>
            Busque uma empresa pelo Nome Fantasia ou CNPJ para visualizar o
            painel
          </p>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
          class="mt-1 pb-1 d-flex flex-row h-100 responsive"
        >
          <h3 class="alinhamento_embaixo">Empresa</h3>
          <v-select
            class="w-100 responsive_espacamento"
            v-model="empresaSelecionada"
            :options="empresas"
            label="razaoCnpjEmpresa"
            placeholder="Nome Fantasia ou CNPJ"
            :clearable="false"
            required
          >
            <span slot="no-options">Nenhuma empresa encontrada.</span>
          </v-select>
          <b-button
            id="botao-redirecionar-dashboard"
            variant="custom"
            type="submit"
            :disabled="empresaSelecionada == null ? true : false"
            @click.prevent="toggleValue()"
          >
            <span class="align-middle">Selecionar</span>
          </b-button>
        </b-col>
      </div>
      <div v-else>
        <dashboardEmpresa
          :empresaSelecionada="empresaSelecionada"
          :dadosFullScreen="dadosFullScreen"
          @redirecionamento-filtragem="toggleValue"
        />
      </div>
    </b-overlay>
  </main>
</template>

<script>
import { BRow, BOverlay, BCol, BButton } from "bootstrap-vue";
import { required } from "@validations";
import vSelect from "vue-select";
import DashboardEmpresa from "@/views/pages/dashboard/saude-mental/DashboardEmpresa.vue";
import { modalGenericFillableErrorWithRedirectHome } from '@/libs/sweetalerts'

export default {
  components: {
    BRow,
    BOverlay,
    BCol,
    BButton, 
    vSelect,
    DashboardEmpresa
  },

  props: {
    dadosFullScreen: {
      type: Object,
      required: false
    },
  },

  data() {
    return {
      loading: true,
      required,
      validadorBotao: false,
      empresaSelecionada: null,
      empresas: [],
      modulo: "Saúde Mental",
    };
  },

  async mounted() {
    await this.preencheSelect(this.modulo);
  },

  methods: {
    async preencheSelect(modulo) {
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.EmpresaBuscarDashboard,
        "{modulo}",
        modulo
      );
      await this.$http
        .get(rota)
        .then((res) => {
          this.empresas = res.data ? res.data : [];
          if (this.$route.params.idEmpresa) {
            this.verificaEmpresaJaSelecionada();
          }else{
            this.verificaDashboardFullScreen()
          }
          this.loading = false;
        })
        .catch(() => {
          modalGenericFillableErrorWithRedirectHome(
            'O módulo atual não foi encontrado, entre em contato ' +
            'com suporte'
          )
        });
    },
    verificaEmpresaJaSelecionada() {
      this.empresaSelecionada = this.empresas.find(
        (item) => item.id_empresa == this.$route.params.idEmpresa
      );
      this.toggleValue()
    },
    verificaDashboardFullScreen() {
      if(
        typeof this.$props.dadosFullScreen !== 'undefined'
      ){
        this.empresaSelecionada = this.empresas.find(
          (item) => item.id_empresa == this.$props.dadosFullScreen.idEmpresa
        )
        this.toggleValue()
      }
    },
    toggleValue() {
      this.validadorBotao = !this.validadorBotao;
    }
  },
};
</script>

<style scoped>
.responsive_espacamento{
    margin: 0 15px;
}
.alinhamento_embaixo {
  display: inline-block;
  align-self: flex-end;
}
@media (max-width: 768px) {
  .responsive{
      display:block !important;
      text-align: center;
  }
  .responsive_espacamento{
      margin: 20px 0;
  }
}
</style>
